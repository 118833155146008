import { graphql, Link } from 'gatsby' 
import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Img, { FixedObject } from 'gatsby-image'

import { IndexQueryQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const Index: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const anablogo17025 = getImage(data.anablogo17025)
  const anablogo17020 = getImage(data.anablogo17020)
  const home1 = getImage(data.home1)
  const home2 = getImage(data.home2)
  const home3 = getImage(data.home3)
  const home4 = getImage(data.home4)
  const home5 = getImage(data.home5)

  // creates scrolled effect on home page only
 
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", function() {  
      const header = document.getElementById('navContainer');  
      const scroll = window.pageYOffset;    
      if (scroll >= 50) {
          header.classList.add("scrolled")
      } else {
          header.classList.remove("scrolled")
      }
    });
  }
  return (
    <Layout location={location}>
      <Meta site={Meta} title="Home" />
      <div>
        <section>
          <div id="comingsoon" className="container px-6">

            <div className="container-fluid mx-0 px-0">
              <h2>
                Helping Make Your Ideas a Reality
              </h2>
              
              <div className="row no-gutters">
                <div className="col-sm-7 col-md-8 col-lg-9">
                  <h3>
                    State-of-the-Art Testing &amp; Inspections for Product Development
                  </h3>
                  <p>The Center for Building Innovation (CBI) is a state-of-the-art research, <Link to='/testing/'>testing</Link>, and Type A (Third Party) <Link to='/inspection/'>inspection</Link> facility, specializing in innovative product development by providing supporting data for approval of alternative materials, products, designs and/or methods of construction. Our customers range from innovative composite material developers to more traditional concrete, wood and steel users.</p>
                  <p>In addition, CBI&rsquo;s expertise includes but is not limited to installed structural assembly R&D, testing and inspections, calibrating test data to establish material properties for use in finite element design programs, and forensic investigations.</p>
                  
                </div>
                <div className="col-sm-5 col-md-4 col-lg-3">
                  <Link to="/accreditation"><GatsbyImage image={anablogo17025} alt="ANAB 17025 Logo. Learn more about our accreditation." className="mx-4 img-fluid mb-0 mt-0" /></Link>
                  <Link to="/accreditation"><GatsbyImage image={anablogo17020} alt="ANAB 17020 Logo. Learn more about our accreditation." className="mx-4 img-fluid mb-0 mt-0" /></Link>
                </div>
              </div>
              
            </div>
            
            <div className="d-md-flex justify-content-sm-between align-items-sm-start mt-2">
              <div className="col-sm-12 col-md-6 px-0">
                <GatsbyImage image={home1} alt="" className="mr-sm-4 mb-4 mb-md-0 ml-2 img-fluid" />
              </div>
              <div className="col-sm-12 col-md-6 px-0">
                <GatsbyImage image={home2} alt="" className="mr-sm-4 ml-2 img-fluid" /> 
              </div>
            </div>
            <div className="d-md-flex justify-content-sm-between align-items-sm-start mt-2">
              <div className="col-sm-12 col-md-6 px-0">
                <GatsbyImage image={home3} alt="" className="mr-sm-4 mb-4 mb-md-0 ml-2 img-fluid" />
              </div>
              <div className="col-sm-12 col-md-6 px-0">
                <GatsbyImage image={home4} alt="" className="mr-sm-4 ml-2 img-fluid" />
              </div>
            </div>
            <div className="d-md-flex justify-content-sm-between align-items-sm-start mt-2">
              <div className="col-sm-12 px-0">
                <GatsbyImage image={home5} alt="" className="mr-sm-4 ml-2 img-fluid" />
              </div>
            </div>
            <hr /> 
            <div className="d-md-flex justify-content-sm-between align-items-sm-start mt-2">
              <div className="col-sm-12 px-0">
                <h2 className="mt-sm-0">
                  Innovation by Listening
                </h2>
                <p>We listen to your needs and work together to find the best testing, quality control, and third party inspection path to success for your distinct innovation. Our collaborative approach leads to tailored solutions with proven results.</p>
                <p>Many innovative groups have told us they would not be where they are today without our staff&rsquo;s help, expertise and commitment. Recent examples include <a href="https://www.diamondage3d.com/">DA3D</a>, <a href="https://www.hwsglobal.com/">HWS Global</a>, <a href="http://www.texassteeltech.com/">TST</a>, <a href="https://www.tstud.com/">Tstud</a>, <a href="https://www.trussbracesolutions.com/">Truss Brace Solutions</a>, and <a href="https://www.weavercooke.com/design-build.html">WEAVERCOOKE DESIGN-BUILD<sup>&trade;</sup></a>.</p>
                <h3 className="mt-sm-0">
                  From needs analysis to success in the market, we help accomplish your mission by:
                </h3>
                <ul>
                  <li>Reviewing the material, product, design, method of construction, and/or service offerings you want to cultivate.</li>
                  <li>Understanding the pain points your innovation addresses.</li>
                  <li>Identifying the unique characteristics that differentiate your innovation in the market.</li>
                  <li>Determining the most effective method(s) for testing.</li>
                  <li>Creating a logic-based test plan that results in accredited test reports.</li>
                  <li>Delivering code-approved <a href="https://up.codes/viewer/wyoming/ibc-2021/chapter/1/scope-and-administration#104.11.1">Research Reports</a> through <a href="https://www.drjcertification.org/technical-evaluation-reports">DrJ Certification</a> that define the performance characteristics of your product or service.</li>
                  <li>Providing approved <a href="https://up.codes/viewer/wyoming/ibc-2021/chapter/1/scope-and-administration#110.4">Inspection Agency</a> reports to streamline building department inspections.</li>
                </ul>
              </div>
            </div>
            <hr />
           
            <div className="container pt-2 mx-0 px-0">

              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">            
                    
                    <h2 className="mt-4">Let&rsquo;s Get Started</h2>
                    <p>
                      Whether you&rsquot;re bringing a new material, product, design, or method of construction to market or are ready to optimize and expand your current offerings, <Link to='/contact/'>contact us</Link> us to learn how our unique approach to <Link to='/testing/'>testing</Link> and <Link to='/inspection/'>inspection</Link> services are designed to wrap our capabilities around your needs.
                    </p>
                    <p>
                      Our team will conduct a free needs analysis to learn more about your product or service and demonstrate how we&rsquo;ll be a good fit for your requirements. <Link to="/about">Learn more</Link> about our technical services and how our family of brands can support your market development journey from idea to implementation. 
                    </p>
                  
                    <hr />
                
                  </div>
                  
                  
                 
                </div>
              </div>

             
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
    anablogo17025: file(name: { eq: "anab-17025" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    anablogo17020: file(name: { eq: "anab-17020" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    home1: file(name: { eq: "Home1" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          height: 300
        )
      }
    }
    home2: file(name: { eq: "Home2" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          height: 300
        )
      }
    }
    home3: file(name: { eq: "Home3" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          height: 300
        )
      }
    }
    home4: file(name: { eq: "Home4" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          height: 300
        )
      }
    }
    home5: file(name: { eq: "Home5" }) {
      childImageSharp {
        gatsbyImageData(
          width: 960
          height: 400
        )
      }
    }
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            tags
            description
            date(formatString: "YYYY/MM/DD")
          }
        }
      }
    }
  }
`
